@import "../../toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.MyCollagesView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - #{$side-bar-width});
  left: $side-bar-width;
  position: absolute;
  top: $header-height;
}
