@import "../../toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.CollageTile {
  @extend %_collageTile;

  ._contentContainer {
    @extend %fade-in-lift;
  }

  ._content__tile {
    transition: 0.2s ease;
    cursor: pointer;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    overflow: hidden;
    border-radius: 12px;
    animation: fade-in 0.5s ease forwards;
    background-image: url("../../app/assets/images/ring-loader.svg");


    ._image {
      width: 200px;
      height: 200px;
      position: absolute;
      object-fit: cover;
      object-position: center;
      left: 0;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0);
    }

    &:hover ._actionsOverlay {
      display: flex;
      animation: fade-in 0.2s linear forwards;
      z-index: 1000;
    }

    &:hover {
      transition: 0.2s ease;
      transform: translateY(-4px) !important;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    }

    ._actionsOverlay {
      display: none;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0);

      .actionButton {
        width:  50px;
        height: 50px;
        color: white;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 8px;
        border-radius: 50%;
        margin-left: 10px;
        margin-right: 10px;

        @extend %button-pulse__extra;
      }
    }
  }
}



@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}