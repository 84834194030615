@import "src/toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.ViewerView {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100vw;
  height: calc(100vh - #{$header-height});
  top: $header-height;
  background-color: #fff;
}