@import "src/toolkit/views/styles/media-query";

.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;

  background-color: #f3f8f8;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/images/homepage-bg-mobile.png");
  padding: 20px 0;

  @include tablet-up {
    background-image: url("../assets/images/homepage-bg-desktop.png");
  }
}

.header {
  display: block;
  flex: 0 0 auto;
}

.logo {
  width: 190px;
  margin: 10px 0 20px;
  cursor: pointer;
}

.collageWrapper {
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  margin-bottom: 32px;
  height: 100%;
}

.collage {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

.footer {
  flex: 0 0 auto;
  display: flex;
}

.actionWrapper {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet-up {
    width: 450px;
  }
}

.socialIconWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
}

.iconWrapper {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #f66161;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include tablet-up {
    width: 95px;
    height: 95px;
  }
}

.icon {
  color: #fff;
  font-size: 24px;
  @include tablet-up {
    font-size: 36px;
  }
}

.restartBtn {
  width: 100%;
  border: none;
  border-radius: 27.5px;
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.7px;
  height: 50px;
  background-color: #f66161;
  box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  cursor: pointer;
  letter-spacing: 1.4px;

  @include tablet-up {
    font-size: 18px;
    max-width: 250px;
  }
}

