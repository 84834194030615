.TextEditorView {
  margin-bottom: 0px;
  padding: 10px;
}

.AppBar {
  .Toolbar {
    display: flex;
    justify-content: space-between;
  }
}

.Tab {
  min-width: 40px !important;
  max-width: 60px !important;
}