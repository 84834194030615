@import "../../toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.AllCollagesView {
  @extend %collageTileGrid;


  ._CollageTile {
    @extend %_collageTile;

    ._content__newCollage {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 200px;
      height: 200px;
      border-radius: 12px;
      transition: 0.2s ease;
      color: #f66161;
      @extend %button-lift;

      ._header {
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 5px;
      }

      ._plus {
        font-size: 80px;
        font-weight: 100;
      }
    }
  }
}