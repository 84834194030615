.TextAlignmentPanelView {
  display: flex;
  flex-direction: row;

  > :first-child {
    margin-left: auto;
  }
  > :last-child {
    margin-right: auto;
  }
}

.TextStrokePanelView {
  display: flex;
  flex-direction: row;
  overflow: visible;

  .Slider {
    height: 66px;
  }
}

.TextColorPanelView {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: scroll;
  max-height: 100px;
  justify-content: safe center;
  align-content: space-between;

  .color {
    min-width: 30px;
    min-height: 30px;
    border-radius: 15px;
    border: 0.5px solid #ccc;
    margin: 2px;

    align-items: stretch;
    align-content: stretch;
    flex-grow: 1;
  }

  .isSelected {
    border: 1.5px solid red;
    box-shadow: #5a5a5a;
    transform: scale(1.15);
  }
}

.checkered {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 8px 8px;
}