@import "src/toolkit/views/styles/media-query";

.StickerChooserView {
  width: 100vw;
  background-color: white;
  touch-action: none;
}

.StickersView {
  display: flex;
  flex-wrap: nowrap;
  transform: translateZ(0);     // Promote the list into his own layer on Chrome.
                                // This cost memory but helps keeping high FPS.

  width: 100vw;
  overflow: auto;
  background-color: #d5e7e5;

  height: 130px;
  padding: 20px;
  @include tablet-up {
    height: 182px;
    padding-left: 50px;
  }
}

.StickerView {
  list-style-type: none;
  height: 100%;
  cursor: pointer;

  padding: 0 20px;
  @include tablet-up {
    padding: 0 25px;
  }

  img {
    height: 100%;
    width: auto;
  }
}

.StickerCategoryView {
  box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.04);
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  overflow: scroll;

  /* justify-content: safe center; */
    /* This doesn't work with `overflow: scroll`, so do `margin-xxxx: auto` below  */

  > :first-child {
    margin-left: auto;
  }
  > :last-child {
    margin-right: auto;
  }
}

.StickerCategoryContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #fff;
  transition: all 0.3s linear;
  overflow: auto;

  height: 52px;
  min-width: 60px;
  @include tablet-up {
    height: 86px;
    min-width: 100px;
  }

  .StickerCategoryLabel {
    font-size: 13px;
    margin: 0;

    display: none;
    @include tablet-up {
      display: inline;
    }
  }

  &.StickerCategoryActive {
    background-color: #f66161;
    border-radius: 5.5px;

    color: #fff;
  }
}

