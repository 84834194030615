@import "src/toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.HeaderView {
  color: #f66161;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  height: $header-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 0;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  animation: fade-in-fall 0.5s linear forwards;
  z-index: 2500;

  ._right {
    position: absolute;
    right: 25px;

    ._buttonLogin {
      width: 100px;
      height: 48px;
      line-height: 48px;
      font-size: 14px;

      @extend %button-pulse;
      @extend %button-white-outline;
    }

    ._buttonLogout {
      width: 160px;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @extend %button-pulse;
      @extend %button-white-outline;

      ._image {
        height: 33px;
        width: 33px;
        border-radius: 50%;
        opacity: 0.8;
        margin-right: 10px;

      }
    }

    ._buttonLogout__noImage {
      width: 100px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @extend %button-pulse;
      @extend %button-white;
      }
    }

  ._left {
    position: absolute;
    left: 25px;
  }
}

@keyframes fade-in-fall {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
  transform: translateY(0px);
    opacity: 1;
  }
}


