@import "src/toolkit/views/styles/media-query";
@import "src/toolkit/views/styles/common";

.EditorView {
  display: flex;
  flex-direction: column;
  position: relative;
  top: $header-height;

  width: 100vw;
  height: 100vh;
  background-color: #fff;

  touch-action: none;
  overflow: hidden;             /* Meant to disable the scroll when dragging */
  overscroll-behavior: none;
  * {
    /* Disable the image and text save long press */
    -webkit-touch-callout: none !important;
    -webkit-user-select:   none !important;
  }

  ._curSketchColor {
    position: absolute;
    left: 30px;
    bottom: 30px;

    height: 50px;
    width: 50px;

    border: 2px solid white;
    border-radius: 6px;
  }
}

.header {
  transform: translateY($header-height); // offset by header height
  @extend %header-common;
  justify-content: center;
}
.footer {
  @extend %footer-common;
  justify-content: center;
}

.sidebar {
  transform: translateY($header-height); // offset by header height
  @extend %sidebar-right-common;
  justify-content: center;
  @extend %button-spacing-vertical;
  height: calc(100% - #{$header-height});
};

.button {
  width: 46px;
  margin: 8px 0 !important;
  @include tablet-up {
    width: 60px;
    margin: 12px 0 !important;
  }
}
.undoGroup {
  height: 46px;
  @include tablet-up {
    height: 60px;
  }
}

.zoomOutButton,
.zoomInButton {
  color: #f66161;
  background-color: #fff;
}

.StrokeView {
  position: absolute;
  height: 100%;
  width:  100%;
  top:  0;
  left: 0;
}