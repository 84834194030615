@import "src/toolkit/views/styles/media-query";
@import "src/toolkit/views/styles/common";

.addPhotoButton,
.addFaceButton,
.addTextButton,
.stickersButton,
.backgroundButton,
._sketchColorButton,
.viewButton,
.backButton,
.shareButton {
  cursor: pointer;
  @extend %button-common;
  @extend %button-lift;

  span {
    display: block;
  }
  &:focus {
    outline: none;
  }
}
.shareButton {
  background-color: #f66161;
}
.viewButton {
  color: orangered;
}

.backButton {
  font-weight: 900;
  color: #f66161;
  font-size: 30px;
  font-family: Consolas, "Courier New";
}

.header {
  transform: translateY($header-height); // offset by header height
  @extend %header-common;
  justify-content: space-between;
}

.sidebuttons {
  display: flex;
  flex-direction: column;
  @extend %button-spacing-vertical;
}

