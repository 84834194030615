@import "src/toolkit/views/styles/media-query";
@import "src/toolkit/views/styles/common";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  user-select: none;
  background-color: gray;

  * {
    touch-action: none;
    overflow: hidden;             /* Meant to disable the scroll when dragging */
    overscroll-behavior: none;

    /* Disable the image and text save long press */
    -webkit-touch-callout: none !important;
    -webkit-user-select:   none !important;
  }


}
.canvas {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}
.footer {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 32px;
}

.header {
  @extend %header-common;
  justify-content: space-between;
};

.sidebar {
  @extend %sidebar-right-common;
  justify-content: center;
  @extend %button-spacing-vertical;
};

.img {
  width:  100%;
  height: 100%;
}
.path_canvas {
  position: absolute;
  top: 0;
  left: 0;

}
.doneButton,
.backButton,
.zoomOutButton,
.zoomInButton {
  @extend %button-common;
}
.doneButton {
  color: #fff;
  background-color: #f66161;
}
.backButton,
.zoomOutButton,
.zoomInButton {
  color: #f66161;
  background-color: #fff;
}

.undoRedoButton {
  width: 46px;
  margin: 8px 0 !important;
  @include tablet-up {
    width: 60px;
    margin: 12px 0 !important;
  }
}
.undoGroup {
  height: 46px;
  @include tablet-up {
    height: 60px;
  }
}


