$icon-size: 60px;

.ScrapView {
  display: block;
  position: absolute;
  height: auto;
  line-height: 0;     // Needs to be set to 0 otherwise will add some padding
}

.scrapDot {
  width:  $icon-size;
  height: $icon-size;

  & + & {
    margin-top: 20px;
  }
  opacity: 0.8;

  .scrapDotIcon {
    width:  $icon-size;
    height: $icon-size;
    padding: 10px;
    background-color: #cccccc;
    border-radius: 75px;
    opacity: 0.8;
  }

}

.scrapBar {
  width:  40px;
  height: $icon-size;

  background-color: #eee;
  opacity: 0.8;
  border-radius: 10px;

}

.focusBackground {
  position: absolute;
  top:    0;
  bottom: 0;
  left:   0;
  right:  0;
  border-radius: 5px;
  opacity: 0.1;
  background-color: gray;
}