@import "../../toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

$tab-height: 45px;

.SideBarView {
  width: $side-bar-width;
  height: 100vh;

  position: fixed;
  top: $header-height;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: white;
  z-index: 500;
  box-shadow: 3px 0px 10px 0 rgba(0, 0, 0, 0.1);
  animation: fade-from-left 0.5s linear forwards;
  font-size: 14px;

  ._tab {
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.075);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #F66161;
    font-weight: 500;
    height: $tab-height;
    width: 100%;
    cursor: pointer;
    transition: 0.15s ease;
    padding-left: 20px;

    &:hover {
      background-color: #F66161;
      color: white;
      transition: 0.15s ease;
    }

    &:hover ._icon {
      color: white;
      transition: 0.15s ease;
    }
  }

  ._tab__selected {
    border-left: solid 5px #F66161;
    border-bottom: solid 0.5px rgba(0, 0, 0, 0.075);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #F66161;
    font-weight: 900;
    background-color: white;
    height: $tab-height;
    width: 100%;
    padding-left: 20px;
  }

  ._icon {
    color: #F66161;
    margin-right: 10px;
    transition: 0.15s ease;
  }
}


@keyframes fade-from-left {
  0% {
    transform: translateX(-40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}