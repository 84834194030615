.TextFontPanelView {
  overflow: scroll;

  .inner {
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 140px;
    justify-content: safe center;
    align-content: space-between;
  }
  .Button {
    width: 140px;
    box-shadow: #5a5a5a;
  }
}
.TextFontView {
  text-transform: none;
  margin: 0 10px;
  font-size: large;
  height: 30px;

}
