$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
