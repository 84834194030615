@import "src/toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.HomeView {
  background-image: url("../assets/images/homepage-bg-mobile.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet-up {
    background-image: url("../assets/images/homepage-bg-desktop.png");
  }

  ._section {
    width: 75vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet-up {
      width: 630px;
    }
  }
  ._version {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
  }

}

.img_left {
  position: fixed;
  bottom: 0;
  left: 0;

  display: none;
  @include tablet-up {
    display: block;
    width: 25vw;
  }
}

.img_right {
  position: fixed;
  bottom: 0;
  right: 0;

  display: none;
  @include tablet-up {
    display: block;
    width: 25vw;
  }
}

.img_bottom {
  position: fixed;
  bottom: 0;
  right: 0;

  display: block;
  @include tablet-up {
    display: none;
    width: 25vw;
  }
}


.img_heading {
  max-width: 100%;
  @include tablet-up {
    max-width: 85%;
  }
  margin-bottom: 15px;
}

._desc {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 0.5px;
  color: #5a5a5a;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  @include tablet-up {
    padding: 0 50px;
    font-size: 24px;
    line-height: 1.46;
    letter-spacing: 0.67px;
  }
}

.button_start {
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 27px;
  background-color: #f66161;
  font-size: 14px;
  color: #fff;
  font-weight: 900;
  letter-spacing: 1.4px;
  text-align: center;
  position: relative;
  z-index: 1;
  border: none;
  outline: none;

  @extend %button-lift;

  @include tablet-up {
    width: 360px;
    height: 75px;
    line-height: 75px;
    font-size: 24px;
    letter-spacing: 2.4px;
    border-radius: 35px;
  }
}
