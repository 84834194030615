.BackdropView {
  background-color: #b0d2ca;
  overflow: hidden;
  position: absolute;
  transform-origin: top left;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 12px 40px 0 rgba(0,0,0,0.19);
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.capture {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
