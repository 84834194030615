$header-height: 75px;
$side-bar-width: 230px;

%header-common {
  position: absolute;

  top: 0px;
  left: 0px;
  right: 0px;
  padding: 12px;
  @include tablet-up {
    padding: 12px;
  }

  display: flex;
  flex-direction: row;

  // Header itself doesn't accept events, but children do
  pointer-events: none;
  & * {
    pointer-events: auto;
  }
}

%footer-common {
  position: absolute;

  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 24px;
  @include tablet-up {
    padding: 32px;
  }

  display: flex;
  flex-direction: row;

  // Header itself doesn't accept events, but children do
  pointer-events: none;
  & * {
    pointer-events: auto;
  }
}

%sidebar-right-common {
  position: absolute;

  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 24px;
  @include tablet-up {
    padding: 32px;
  }

  display: flex;
  flex-direction: column;
  pointer-events: none;

  // Header itself doesn't accept events, but children do
  pointer-events: none;
  & * {
    pointer-events: auto;
  }
}

%sidebar-left-common {
  position: absolute;

  top: 0px;
  bottom: 0px;
  left: 0px;
  padding: 32px;
  @include tablet-up {
    padding: 72px;
  }

  display: flex;
  flex-direction: column;
  pointer-events: none;

  // Header itself doesn't accept events, but children do
  pointer-events: none;
  & * {
    pointer-events: auto;
  }
}

%button-common {
  width: 46px;
  height: 46px;
  @include tablet-up {
    width: 60px;
    height: 60px;
  }

  border-radius: 50%;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
}

%button-pulse {
  cursor: pointer;
  transition: 0.1s ease;
  &:hover {
    transition: 0.1s ease;
    transform: scale(1.05, 1.05);
  }

  &:active {
    transition: 0.1s ease;
    filter: brightness(85%);
    transform: scale(0.95, 0.95);
  }
}

%button-pulse__extra {
  cursor: pointer;
  transition: 0.1s ease;
  &:hover {
    transition: 0.1s ease;
    transform: scale(1.2, 1.2);
  }

  &:active {
    transition: 0.1s ease;
    filter: brightness(85%);
    transform: scale(0.90, 0.90);
  }
}

%button-lift {
  transition: 0.4s ease;
  cursor: pointer;
  &:hover {
    transition: 0.2s ease;
    transform: translateY(-4px) !important;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  }

  &:active {
    transition: 0.1s ease;
    filter: brightness(85%);
    transform: scale(0.95, 0.95) !important;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  }
}

%button-spacing-horizontal {
  & button + button {
    margin-left: 10px;
  }
}

%button-spacing-vertical {
  & button + button {
    margin-top: 10px;
  }
}

%button-red {
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
  position: relative;
  font-weight: 900;
  border-radius: 27px;
  background-color: #f66161;
  font-size: 14px;
  color: #fff;
  letter-spacing: 2px;
}

%button-white-outline {
  cursor: pointer;
  outline: none;
  text-align: center;
  position: relative;
  font-weight: 900;
  border-radius: 27px;
  background-color: white;
  border: solid 2px #f66161;
  font-size: 14px;
  color: #f66161;
  letter-spacing: 2px;
}

%button-white {
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
  position: relative;
  font-weight: 900;
  border-radius: 27px;
  background-color: #ffffff;
  font-size: 14px;
  color: #f66161;
  letter-spacing: 2px;
}

// Prevents text in elements from being highlighted
%no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

%bg-glass {
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
}

%fade-in-lift {
  animation: fade-in-lift 0.3s linear forwards;

  @keyframes fade-in-lift {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

%collageTileGrid {
  display: grid;
  grid-gap: 1rem;
  text-align: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: calc(100vw - #{$side-bar-width});
  padding: 40px;
  margin: 0;

  %_collageTile {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}





