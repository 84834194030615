@import "src/toolkit/views/styles/media-query";
@import "../../toolkit/views/styles/common";

.HomeViewVariant1 {
  background-image: url("../assets/images/homepage-variant-1-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.5s linear forwards;
}


.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button_login {
  margin-top: 300px; // A bit below center
  width: 150px;
  height: 54px;
  line-height: 54px;
  z-index: 1;
  font-size: 20px;

  @extend %button-lift;
  @extend %button-white-outline;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

